<template>
    <!-- Seitentitel und Buttons -->
    <div v-if="showHeader" class="page-header surface-section mb-4">
        <div class="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
            <div class="pt-1">
                <div class="font-medium text-2xl text-800" data-test="page-title">{{ title }}<span class="ml-2"><slot
                    name="title-addition"></slot></span></div>
            </div>
            <div id="page-header-buttons"
                 class="page-header-buttons mt-3 lg:mt-0 flex align-items-start flex-column md:flex-row flex-wrap w-full md:w-auto">
                <slot name="buttons"></slot>
            </div>
        </div>
    </div>

    <!-- Notifications -->
    <transition-group name="p-message" tag="div" data-test="notification">
        <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id">{{ msg.content }}</Message>
    </transition-group>

    <Toast position="top-center"></Toast>
    <ConfirmDialog data-test="confirm-dialog" :style="{ width: '50vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }"></ConfirmDialog>

    <!-- Loading-Spinner-->
    <Dialog :visible="loading" :modal="true" :closable="false" :show-header="false" class="loading-dialog">
        <ProgressSpinner></ProgressSpinner>
    </Dialog>
</template>

<script setup lang="ts">
import {onMounted, onUpdated, PropType} from 'vue';
import {NotificationMessage} from '@/model/NotificationMessage';

const props = defineProps({
    title: String,
    loading: Boolean,
    messages: {
        type: Array as PropType<Array<NotificationMessage>>,
        required: true
    },
    showHeader: {
        type: Boolean,
        default: true
    }
})

onMounted(() => {
    addClassesToButtons();
});

onUpdated(() => {
    addClassesToButtons();
});

function addClassesToButtons() {
    const pageHeaderButtons = document.getElementById('page-header-buttons');
    if (pageHeaderButtons) {
        const buttons = pageHeaderButtons.getElementsByTagName('button');
        for (let button of buttons) {
            if (!button.classList.contains('w-full')) {
                button.classList.add('w-full', 'md:w-auto');
            }
        }
    }
}
</script>

<style>
.page-header .page-header-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.page-header .page-header-buttons button:last-of-type {
    margin-right: 0;
}

.loading-dialog {
    box-shadow: none !important;
    background: none;
}

.loading-dialog .p-dialog-content {
    background: none !important;
}

.p-component-overlay {
    /* Hintergrundfarbe des Overlays etwas aufhellen */
    background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
