import axios, {AxiosRequestHeaders} from 'axios';

import JsonUtil from '@/utils/JsonUtil';

export default abstract class AbstractRestClientService {

    private readonly apiBasePath: string = '/api';

    protected sendGetForDownload(path: string) {

        const url = this.apiBasePath + path;

        return axios.request({
            url: `${url}`,
            responseType: 'blob',
            headers: this.headers()
        });
    }

    protected sendGet(path: string) {

        const url = this.apiBasePath + path;

        return axios.request({
            url: `${url}`,
            headers: this.headers(),
            transformResponse: (response: any) => {
                const obj: any = JSON.parse(response, this.dateParser);
                return obj;
            }
        });
    }

    protected sendDelete(path: string) {

        const url = this.apiBasePath + path;

        return axios.delete(url, {
            headers: this.headers()
        });
    }

    protected sendPost(path: string, obj: any) {

        const objAsJson = JsonUtil.stringify(obj);
        const url = this.apiBasePath + path;

        return axios.post(url, objAsJson, {
            headers: this.headers()
        });
    }

    protected sendPostWithoutBody(path: string) {

        const url = this.apiBasePath + path;

        return axios.post(url, null, {
            headers: this.headers()
        });
    }

    protected sendPut(path: string, obj: any) {

        const objAsJson = JsonUtil.stringify(obj);
        const url = this.apiBasePath + path;

        return axios.put(url, objAsJson, {
            headers: this.headers()
        });
    }

    private dateParser = (key: string, value: string) => {

        const regexpDate = /^\d{4}-\d{2}-\d{2}$/;
        const regexpDateTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/;

        const isDate = regexpDate.exec(value);
        const isDateTime = regexpDateTime.exec(value);

        if (isDate || isDateTime) {
            return new Date(value);
        }

        return value;
    }

    private headers(): AxiosRequestHeaders {
        const storedUser = localStorage.getItem('user');
        const user = JSON.parse(storedUser ? storedUser : '');

        if (user && user.accessToken) {
            return {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.accessToken
            };
        } else {
            return {
                'Content-Type': 'application/json',
            };
        }
    }
}
