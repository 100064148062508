<template>
    <page-header :show-header="false" :messages="notification.messages"></page-header>

    <div class="dashboard md:h-full">
        <div class="text-900 font-bold text-6xl mb-2 text-center" data-test="page-title">Hallo
            <span v-if="user">{{ user.displayname }}</span>!
        </div>
        <div class="text-700 text-xl mb-4 text-center line-height-3">Willkommen zur IGS-Bestellverwaltung</div>

        <div class="grid dashboard-grid">
            <div class="col-12 lg:col-4 lg:h-full">
                <div class="p-1 lg:h-full">
                    <div class="surface-card p-3 shadow-2 border-round flex flex-column lg:h-full dashboard-card">
                        <Button label="Bestellung anlegen"
                                class="p-button-secondary p-button-lg w-full mt-1 mb-1 dashboard-shortcut"
                                @click="router.push('/bestellungen/neu')"
                                data-test="home-shortcut-bestellung-anlegen"/>
                        <Button label="Bestellungen"
                                class="p-button-secondary p-button-lg w-full mt-1 mb-1 dashboard-shortcut"
                                @click="router.push('/bestellungen')"
                                data-test="home-shortcut-bestellungen"/>
                        <Button label="Sammelbestellungen"
                                class="p-button-secondary p-button-lg w-full mt-1 mb-1 dashboard-shortcut"
                                v-if="userStatus.isAdmin"
                                @click="router.push('/sammelbestellungen')"
                                data-test="home-shortcut-sammelbestellungen"/>
                        <Button label="Kunden"
                                class="p-button-secondary p-button-lg w-full mt-1 mb-1 dashboard-shortcut"
                                v-if="userStatus.isKassierer"
                                @click="router.push('/kunden')"
                                data-test="home-shortcut-kunden"/>
                        <Button label="Kalender"
                                class="p-button-secondary p-button-lg w-full mt-1 mb-1 dashboard-shortcut"
                                v-if="userStatus.isAdmin"
                                @click="router.push('/kalender')"
                                data-test="home-shortcut-kalender"/>
                        <Button label="Kasse" class="p-button-secondary p-button-lg w-full mt-1 mb-1 dashboard-shortcut"
                                v-if="userStatus.isKassierer"
                                @click="router.push('/kasse')"
                                data-test="home-shortcut-kasse"/>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-4 lg:h-full">
                <div class="p-1 lg:h-full">
                    <div class="surface-card p-3 shadow-2 border-round flex flex-column lg:h-full dashboard-card">
                        <!-- Die Höhe ist durch Versuche ermittelt worden - eine Logik ist nicht erkennbar :-) -->
                        <Chart :type="chartOptions.type" :data="chartOptions.data" :options="chartOptions.options"
                               :height="340"/>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-4 lg:h-full">
                <div class="p-1 lg:h-full">
                    <div class="surface-card p-3 shadow-2 border-round flex flex-column lg:h-full dashboard-card">
                        <DataView :value="aufgaben" layout="list">
                            <template #empty>
                                <Card class="mt-1">
                                    <template #content>
                                        <div>Es gibt gerade keine aktuellen Aufgaben zu erledigen.</div>
                                    </template>
                                </Card>
                            </template>
                            <template #list="slotProps">
                                <Card v-for="(item, index) in slotProps.items" :key="index" class="mt-1 mb-2" data-test="aufgabe-card">
                                    <template #title>
                                        <div>{{ item.titel }}</div>
                                    </template>
                                    <template #content>
                                        <div>{{ item.text }}</div>
                                    </template>
                                    <template #footer>
                                        <div class="align-right">
                                            <Button type="button" icon="pi pi-check-square" label="Erledigt"
                                                    v-if="userStatus.isAdmin"
                                                    @click="closeAufgabe(item)"
                                                    data-test="close-aufgabe-button">
                                            </Button>
                                        </div>
                                    </template>
                                </Card>
                            </template>
                        </DataView>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--        <img alt="il Gusto Siciliano" src="../assets/logo.gif" width="30%" style="margin-top: 30px">-->
</template>

<script setup lang="ts">
import {onMounted, reactive, ref, Ref} from 'vue';
import {useStore} from '@/store';
import {useRouter} from 'vue-router';
import {UserStatus} from '@/model/UserStatus';
import {AuthenticatedUser} from '@/model/AuthenticatedUser';
import {Aufgabe} from '@/model/Aufgabe';
import {aufgabeService} from '@/services/AufgabeService';
import {useNotification} from '@/composables/Notification';
import PageHeader from '@/components/PageHeader.vue';
import {statistikService} from '@/services/StatistikService';

const store = useStore();
const router = useRouter();
const user: Ref<AuthenticatedUser> = ref(store.state.user);
const userStatus: Ref<UserStatus> = ref(store.state.status);
const notification = reactive(useNotification());
const aufgaben: Ref<Aufgabe[]> = ref([]);
const chartOptions: Ref<any> = ref({});

onMounted(() => {
    loadChart();
    loadAufgaben();
});

function loadChart() {
    statistikService.getStatistikAuslastungNaechsteAbholtage()
        .then((response) => {
            chartOptions.value = response;
        })
        .catch((error: any) => {
            notification.handleApiError(error, 'Beim Lesen des Charts ist ein Fehler aufgetreten!');
        });
}

function loadAufgaben() {
    aufgabeService.getOffeneAufgaben()
        .then((response: any) => {
            const aufgabenList: Aufgabe[] = response.data.content;
            if (aufgabenList.length === 1 && !aufgabenList[0].id) {
                aufgaben.value = [];
                return;
            }

            aufgaben.value = aufgabenList;
        })
        .catch((error: any) => {
            notification.handleApiError(error, 'Beim Lesen der Aufgaben ist ein Fehler aufgetreten!');
        });
}

function closeAufgabe(aufgabe: Aufgabe) {
    notification.confirmDialog('Willst du die Aufgabe als erledigt markieren?', () => {
        aufgabe.erledigtAm = new Date();
        aufgabeService.saveAufgabe(aufgabe)
            .then((response: any) => {
                notification.toastInfo('Die Aufgabe ist als erledigt markiert.');
                loadAufgaben();
            })
            .catch((error: any) => {
                notification.handleApiError(error, 'Beim Speichern der Aufgabe ist ein Fehler aufgetreten!');
            })
    })
}
</script>

<style scoped>
.dashboard-grid {
    height: calc(100% - 130px);
}

.dashboard-card {
    overflow: auto;
}

.dashboard-shortcut {
    height: 3.5rem;
}
</style>
